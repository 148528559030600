#root {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#graph {
  height: 100%;
}

.container {
  width: 80%;
  padding: 2rem;
}

.application {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0;
}

.ledger-div {
  display: flex;
  flex-direction: row;
}

.visualizer-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.instructions {
  text-align: right;
  color: #999999;
}

.totals {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
}

.sub-totals {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section {
  border-left: 1px solid #000000;
  margin: 1rem 0 1rem 1rem;
  padding-left: 1rem;
}

.detail {
  display: flex;
  flex-direction: row;
}

#header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-size: 24px;
}

#name {
  display: flex;
  align-items: center;
  flex-direction: row;
}

#title {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

#title span {
  padding-left: 10px;
  color: #999999;
  transition: all 0.3s ease;
}

#title span:hover {
  color: #000000;
}

#nav {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 24px;
  text-align: left;
}

.about {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 24px;
  margin-bottom: 1rem;
}
.about a {
  font-size: 20px;
  color: #999999;
  text-decoration: none;
  align-content: center;
  margin: 0 10px;
  transition: all 0.5s ease;
}

.about a:hover {
  color: #000000;
}

.select {
  color: #999999;
  border: none;
  font: inherit;
  outline: none;
  padding: 0;
  transition: all 0.5s ease;
  -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
}

.data-options, 
.sub-opt {
  display: none;
  z-index: 1;
  position: absolute;
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 1px 5px #eae8e8;
  border: 0 1px 1px #eae8e8;
  transition: all 0.3s ease-in-out;
}

.data-options a, 
.sub-opt a {
  color: #999999;
  text-decoration: none;
  margin: 5px;
  transition: all 0.5s ease;
}

.data-options a:hover, 
.sub-opt a:hover,
.select:hover {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}


.data-selector:hover .data-options,
.data-selector:hover .sub-opt {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  min-width: 125px;
  max-width: 300px;
  overflow: auto;
}

#demographic {
  padding: 0 10px;
}

#demoSelector {
  margin: 0 6px;
}

.value {
  display: flex;
  transition: all 0.3s ease;
  color: #000000;
  border-right: 2px solid #000000;
}


.ledger-item {
  color: #999999;
  margin: 0.80rem 0;
  display: flex;
  flex-direction: row;
}

.ledger-item span {
  margin-left: 1rem;
}

.sized {
  width: 4rem;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #999999;
  font-size: 10px;
  border-top: 1px solid lightgrey;
  padding-top: 1rem;
}

.copy {
  display: flex;
  justify-content: space-between;
}


@media (max-width: 1080px) {

  #root {
    height: auto;
  }

  .application {
    flex-direction: column-reverse;
  }

  .container {
    width: 100%;
    padding: 0;
  }

  #float {
    text-align: center;
    margin-top: 1rem;
  }

  #header {
    flex-direction: column;
  }

  #title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  #name {
    margin-top: 1.5rem;
    flex-direction: column;
  }

  #nav {
    flex-direction: column;
    width: 100%;
  }

  .section {
      margin: 1rem 1rem 1rem 0;
  }

  .instructions {
    text-align: center;
  }

  .footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 1rem;
    justify-content: space-between;
    color: #999999;
    font-size: 10px;
    border-top: 1px solid lightgrey;
    padding: 1rem 0;
  }

  .copy {
    flex-direction: column;
  }

  #demoSelector {
    margin: 0;
  }

  .data-selector {
    text-align: center;
    width: 100%;
  }

  #sub,
  #demographic {
    padding: 0;
    width: 100%;
  }

  .data-options {
    text-align: center;
    width: 100%;
  }

  .sub-opt {
    text-align: center;
    width: 100%;
  }

  .data-selector:hover .data-options,
  .data-selector:hover .sub-opt {
    display: flex;
    flex-direction: column;
    max-height: none;
    min-width: none;
    max-width: none;
    overflow: auto;
  }

}